// angular import
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DomainService } from './theme/shared/service/domain.service';
import { Customized } from './demo/Model/Customized';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { ParameterCustomized } from './demo/Model/Enum/ParameterCustomized';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  // public props
  title = 'datta-able';
  parameterCustomized: any;
  imgLogo = "";
  titleAba = "";

  // constructor
  constructor(private router: Router, 
    private domainService: DomainService, 
    private titleService: Title,  
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,) {
      this.renderer.setAttribute(document.documentElement, 'translate', 'no');
      this.renderer.setAttribute(document.documentElement, 'lang', 'en'); // Altere para 'en' se for inglês
    }

  async ngOnInit() {

    await this.getParameterCustomized();

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    
  }

  getDomain(): String{
    // Obtém apenas o domínio
    const url = window.location.href;
    const parsedUrl = new URL(url);
    const domain = parsedUrl.hostname; 
    return domain;
  }
 
  getParameterCustomized(){
    const dominio = this.getDomain();
    console.log(dominio);
    this.domainService.getParameterCustomized(dominio).then(
      (response: Customized) => {
        this.parameterCustomized = response;
        console.log(response);
        localStorage.setItem("customized", JSON.stringify(response));
        this.setParameterCustomized();
      },
      (error) => {
        console.error(error);
      }
    )
  }

  setParameterCustomized(){
    this.imgLogo = this.domainService.getParameter(ParameterCustomized.FAVICON).value;
    this.titleAba = this.domainService.getParameter(ParameterCustomized.TITLEABA).value; 
    this.setFavicon(this.imgLogo);
    this.titleService.setTitle(this.titleAba);

  }

  setFavicon(iconUrl: string) {
    const link: HTMLLinkElement = this.document.querySelector("link[rel*='icon']") || this.renderer.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = iconUrl;

    if (!link.parentElement) {
      this.renderer.appendChild(this.document.head, link);
    }
  }
}
