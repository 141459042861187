// angular import
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// project import
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './theme/shared/shared.module';
import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';
import { ToastrModule } from 'ngx-toastr';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorInterceptor } from 'src/app/theme/shared/_helpers/error.interceptor';
import { BasicAuthInterceptor } from 'src/app/theme/shared/_helpers/basic-auth.interceptor';
import { HttpInterceptorService, HTTPListener } from './theme/shared/service/http-interceptor.service';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig, CurrencyMaskModule } from 'ng2-currency-mask';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { QrCodeModule } from 'ng-qrcode';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, } from '@angular/material/table';
import { Subscription } from 'rxjs';


const RxJS_Services = [HTTPListener, HttpInterceptorService];
export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: "."
};

@NgModule({
  declarations: [AppComponent, ToggleFullScreenDirective],
  bootstrap: [AppComponent], imports: [BrowserModule, BrowserAnimationsModule,
    AppRoutingModule, MatSlideToggleModule,
    FormsModule, MatButtonModule,
    ReactiveFormsModule, MatTableModule,
    MatCardModule, QrCodeModule,
    SharedModule, MatGridListModule, MatPaginatorModule,
    CurrencyMaskModule,
    NgxUiLoaderModule, MatFormFieldModule, MatInputModule, MatIconModule, MatSortModule,
    ToastrModule.forRoot()],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HTTPListener, multi: true },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    Location,
    RxJS_Services,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
  private subs: Subscription[] = [];
  constructor(private httpStatus: HttpInterceptorService, private loaderService: NgxUiLoaderService) {
    this.subs.push(this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
      
      const isExcludedRequest = this.httpStatus.getLastRequestUrl() ?
        ['/management-business/v1/onboarding/proposal-pj' ,
          '/management-business/v1/onboarding/proposal-pf' ,
          '/management-business/v1/payments/list/infoPayments' ,
          '/management-business/v1/account/balance', 
          '/management-business/v1/payments/consent/find/id',
           '/management-business/v1/onboarding/proposal/documentoscopy', 
           '/management-business/v1/account/statement'].some(url => this.httpStatus.getLastRequestUrl().split('?')[0].includes(url))
        : false;

      if (status && !isExcludedRequest) {
        this.loaderService.start();
      }
      else {
        this.loaderService.stop();
      }
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      if (sub) {
        sub.unsubscribe();
      }
    })
  }
}
