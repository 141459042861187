import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cpfCnpjMask',
  standalone: true
})
export class CpfCnpjMaskPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';

    const onlyNumbers = value.replace(/\D/g, '');

    if (onlyNumbers.length === 11) {
      return onlyNumbers.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '***.$2.$3-**');
    } else if (onlyNumbers.length === 14) {
      return onlyNumbers.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '**.$2.$3/$4-**');
    }

    return value;
  }
}
