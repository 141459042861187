export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: NavigationItem[];
}
export const NavigationItems: NavigationItem[] = [
  {
    id: 'navigation',
    title: '',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'home',
        title: 'Home',
        icon: '../../../../../../assets/images/widget/home.svg',
        type: 'item',
        url: '/dashboard/home'
      },
      {
        id: 'conta',
        title: 'Minha Conta',
        icon: '../../../../../../assets/images/widget/icon-emprestimo.svg',
        type: 'item',
        url: '/dashboard/home'
      },
      // {
      //   id: 'filial',
      //   title: 'Filiais',
      //   icon: '../../../../../../assets/images/widget/company-icon.svg',
      //   type: 'item',
      //   url: '/cadastrar/Filial/Empresa'
      // },
      // {
      //   id: 'account',
      //   title: 'Conta Pagamentos',
      //   icon: '../../../../../../assets/images/widget/icon-emprestimo.svg',
      //   type: 'item',
      //   url: '/cadastro/Conta/Pagamento'
      // },
      // {
      //   id: 'cliente',
      //   title: 'Clientes',
      //   icon: '../../../../../../assets/images/widget/users.svg',
      //   type: 'item',
      //   url: '/Cadastrar/Clientes/Empresa'
      // },
      {
        id: 'links',
        classes: 'links',
        title: 'Gerar Links',
        icon: '../../../../../../assets/images/widget/icon-export.svg',
        type: 'item',
        url: '/Pagamento/cliente/Link'
      },
      {
        id: 'chave',
        classes: 'chave',
        title: 'Chaves de Acesso',
        icon: '../../../../../../assets/images/widget/key.svg',
        type: 'item',
        url: '/integracao/api/chave'
      }
    ]
  }
];