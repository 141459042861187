import { Component, EventEmitter, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/theme/shared/shared.module';
import { NavRightComponent } from './nav-right/nav-right.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/theme/shared/service/authentication.service';
import { CpfCnpjMaskPipe } from 'src/app/theme/shared/filter/cpf-cnpj-mask.pipe';

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [SharedModule, NavRightComponent, RouterModule, CommonModule, CpfCnpjMaskPipe],
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {
  @Output() NavCollapsedMob = new EventEmitter();
  navCollapsedMob;
  headerStyle: string;
  menuClass: boolean;
  collapseStyle: string;
  userName: string = '';
  completeUserName: string = '';
  cpfUser: string = '';
  accountNumber: string = '';
  agencyNumber: string = '';

  hasAccount: boolean = false;

  constructor(private authenticationService: AuthenticationService,
              private translate: TranslateService,
              private router: Router) {
    this.navCollapsedMob = false;
    this.headerStyle = '';
    this.menuClass = false;
    this.collapseStyle = 'none';
  }

  ngOnInit() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    if (currentUser && currentUser.nameCustomer) {
      this.userName = currentUser.nameCustomer.split(' ')[0];
      this.completeUserName = currentUser.nameCustomer;
      this.cpfUser = currentUser.cpfCnpj;
      this.accountNumber = currentUser.account;
      this.agencyNumber = currentUser.branch.slice(-2);
    }

    if(currentUser.account != null){
      this.hasAccount = true;
    }
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  logout() {
    this.authenticationService.logout();
  }

  goToSettings() {
    this.router.navigate(['/settings']);
  }

  toggleMobOption() {
    this.menuClass = !this.menuClass;
    this.headerStyle = this.menuClass ? 'none' : '';
    this.collapseStyle = this.menuClass ? 'block' : 'none';
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeMenu();
    }
  }

  closeMenu() {
    if (document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open')) {
      document.querySelector('app-navigation.pcoded-navbar').classList.remove('mob-open');
    }
  }
}
