import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../service/authentication.service';
import { NotificationService } from '../service/notification.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService, private notifyService: NotificationService) {}

  intercept(request: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          this.authService.logout();
          this.notifyService.ngAlertErro("Sessão expirada", "Por favor faça login novamente!");
        }
        const errorMessage = err.error?.errorMessage || err.error?.titleErrorMessage || err?.errorMessage ||  "Aconteceu um erro inesperado!";

        return throwError({ status: err.status, message: errorMessage });
      })
    );
  }
}
